@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 300; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Light.otf") format("opentype");
}

@font-face {
  font-family: "SatoshiBold";
  src: url("./assets/fonts/Satoshi-Black.otf") format("opentype");
}

@font-face {
  font-family: "SatoshiM";
  src: url("./assets/fonts/Satoshi-Medium.otf") format("opentype");
}
