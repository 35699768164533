:root {
  --secondary: #2242b4;
  --main: #050038;
}

@import url("typography.css");
.inter {
  font-family: Inter;
}
.satoshi {
  font-family: Satoshi;
}
.bolder {
  font-family: SatoshiBold !important;
}
.bold {
  font-family: SatoshiBold !important;
  font-weight: 700;
}
.medium {
  font-family: SatoshiM !important;
  font-weight: 500;
}
.karla {
  font-family: Karla;
}
.kum {
  font-family: Kum;
}
.manrope {
  font-family: Manrope;
}
.midText {
  @apply tw-text-sm tw-font-medium tw-text-scblue satoshi;
}
.smallText {
  @apply tw-text-sm tw-font-normal tw-text-scblue satoshi;
}
.baseText {
  @apply tw-text-base tw-font-normal tw-text-scblue satoshi;
}
.loginbg {
  background-image: url("./assets/loginbg.png");
  background-position: center;
  background-size: cover;
}
p {
  padding: 0 !important;
  margin: 0 !important;
}
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Change the value of 4 to adjust the number of lines */
  -webkit-line-clamp: 2;
}
.herobg {
  background-image: linear-gradient(to right, #0a1c2d99 50%, transparent 50%),
    url("./assets/herobg.png");
  background-size: cover;
  background-position: center;
}
.leaderboardbg {
  background-image: url("./assets/termbg.png");
  background-size: cover;
  background-position: center;
}
.termbg {
  background-image: url("./assets/leadbg.png");
  background-size: cover;
  background-position: center;
}
.triviabg {
  background-image: url("./assets/triviabg.png");
  background-size: cover;
  background-position: center;
}
.trivia {
  background-image: url("./assets/Vector\ 4.svg");
  background-size: cover;
  background-position: center;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.contactbg {
  background-image: url("./assets/contactbg.png");
  background-position: center;
  background-size: cover;
}
.clip {
  background: transparent;
  -webkit-clip-path: polygon(
    1% 0,
    80% 0%,
    100% 20%,
    100% 100%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
  clip-path: polygon(
    1% 0,
    80% 0%,
    100% 20%,
    100% 100%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--secondary);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #00000066;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--main);
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
